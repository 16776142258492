import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import axios from '../../Utils/axios'
import { all_stats, url_track_details } from "../../Utils/Urls";
import { Toast } from 'primereact/toast';
import './stat.css'
import { useLocation } from 'react-router-dom';

export default function Stat() {
    const toast = useRef(null);
    const location = useLocation();
    const [customers, setCustomers] = useState(null);
    const [isMounted, setIsMounted] = useState(false)
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        first_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        first_source: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        first_medium: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        first_campaign: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        first_content: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [Visible, setVisible] = useState(false);
    const [tracker, setTracker] = useState(null);
    const [trackLoading, setTrackLoading] = useState(true);


    useEffect(()=>{
        axios.get(all_stats, {
            headers: {'Content-Type': 'application/json'},
            })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setCustomers(getCustomers(response.data));
                    setLoading(false);
                    }
                })
            .catch((error)=>{
                console.log(error.response.data)
                setLoading(true); 
            })
        
    },[all_stats, isMounted])

    const getCustomers = (data) => {
        return [...(data || [])].map((d) => {
            d.created = new Date(d.created);

            return d;
        });
    };

    const getTrackers = (data) => {
        return [...(data || [])].map((d) => {
            d.clicked_at = new Date(d.clicked_at);

            return d;
        });
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Global Search" />
                </span>
            </div>
        );
    };

    const countryBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt="flag" src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`flag flag-${rowData.country.code}`} style={{ width: '24px' }} />
                <span>{rowData.country.name}</span>
            </div>
        );
    };

    const representativeBodyTemplate = (rowData) => {
        const representative = rowData.representative;

        return (
            <div className="flex align-items-center gap-2">
                <img alt={representative.name} src={`https://primefaces.org/cdn/primereact/images/avatar/${representative.image}`} width="32" />
                <span>{representative.name}</span>
            </div>
        );
    };

    const representativesItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt={option.name} src={`https://primefaces.org/cdn/primereact/images/avatar/${option.image}`} width="32" />
                <span>{option.name}</span>
            </div>
        );
    };

    // const statusBodyTemplate = (rowData) => {
    //     return <Tag value={rowData.status} severity={getSeverity(rowData.status)} />;
    // };

    // const statusItemTemplate = (option) => {
    //     return <Tag value={option} severity={getSeverity(option)} />;
    // };

    const verifiedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.verified, 'false-icon pi-times-circle': !rowData.verified })}></i>;
    };

    // const representativeRowFilterTemplate = (options) => {
    //     return (
    //         <MultiSelect
    //             value={options.value}
    //             options={representatives}
    //             itemTemplate={representativesItemTemplate}
    //             onChange={(e) => options.filterApplyCallback(e.value)}
    //             optionLabel="name"
    //             placeholder="Any"
    //             className="p-column-filter"
    //             maxSelectedLabels={1}
    //             style={{ minWidth: '14rem' }}
    //         />
    //     );
    // };

    // const statusRowFilterTemplate = (options) => {
    //     return (
    //         <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
    //     );
    // };

    const verifiedRowFilterTemplate = (options) => {
        return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        const db_outcome = rowData.outcome
        return <div className='status'>{rowIndex}</div>;
      };

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
        // }).replace(/(\d+)\/(\d+)\/(\d+)/, '$2/$1/$3'); // Rearrange the format to DD/MM/YYYY;
    };

    const formatTrackDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        })
        // }).replace(/(\d+)\/(\d+)\/(\d+)/, '$2/$1/$3'); // Rearrange the format to DD/MM/YYYY;
    };

    const formatDateTime = (value) => {
        const formattedDate = formatDate(value);
        
        return formattedDate;
    };

    const formatTrackDateTime = (value) => {
        const formattedDate = formatTrackDate(value);
        
        return formattedDate;
    };

    const dateCreatedTemplate = (rowData) => {
    return(
        <div className='status'>
        {formatDateTime(rowData.created)}
        </div>
    )
    };

    const clickedAtTemplate = (rowData) => {
        return(
            <div className='status'>
            {formatTrackDateTime(rowData.clicked_at)}
            </div>
        )
        };

    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url)
          .then(() => {
            toast.current.show({ severity: 'info', summary: 'Copied', detail: 'URL copied to the clipboard' });
          })
          .catch(err => {
            console.error('Failed to copy URL to clipboard', err);
          });
      };

    const baseUrlTemplate = (rowData) => {
        return(
            <div className='status flex-copy'>
            <a href={rowData.base_url} target="_blank" rel="noopener noreferrer">{rowData.base_url}</a>
            <i className="pi pi-copy copy-icon" style={{ fontSize: '1.5rem' }} onClick={()=> copyToClipboard(rowData.base_url)} />
            </div>
        )
    };

    const longUrlTemplate = (rowData) => {
        return(
            <div className='status flex-copy'>
            <a href={rowData.long_url} target="_blank" rel="noopener noreferrer">{rowData.long_url}</a>
            <i className="pi pi-copy copy-icon" style={{ fontSize: '1.5rem' }} onClick={()=> copyToClipboard(rowData.long_url)}/>
            </div>
        )
    };

    const shortUrlTemplate = (rowData) => {
        return(
            <div className='status flex-copy'>
            <a href={rowData.final_short_url} target="_blank" rel="noopener noreferrer">{rowData.final_short_url}</a>
            <i className="pi pi-copy copy-icon" style={{ fontSize: '1.5rem' }} onClick={()=> copyToClipboard(rowData.final_short_url)}/>
            </div>
        )
    };

    const ClickDetailsTemplate = (rowData) => {
        const shortner_id = rowData.id
        return <Button className='stats-button' icon="pi pi-external-link" onClick={()=>handleTrackButtonClick(shortner_id)}/>
    };

    const handleTrackButtonClick = (shortner_id) =>{
        setVisible(true)
        const data = {
            shortner_id
        }

        axios.post(url_track_details, data, {
            headers: {'Content-Type': 'application/json'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setTracker(getTrackers(response.data));
                    setTrackLoading(false);
                  }
                })
            .catch((error)=>{
                console.log(error.response.data)
                setTrackLoading(true); 
            })
    }

    const handleOnHide = () =>{
        setVisible(false)
        setTrackLoading(true)
    }

    const header = renderHeader();

    return (
        <div className="card stat">
            <Toast ref={toast} />
            <DataTable value={customers} showGridlines paginator rows={10} dataKey="id" filters={filters} loading={loading}
                    globalFilterFields={['first_campaign', 'first_content', 'first_medium', 'first_name', 'first_source', 'long_url', 'phone', 'short_url', 'times_followed']} header={header} emptyMessage="No Data..">
                <Column header="SR NO" className="padding-0" style={{ minWidth: '3rem'  }} body={srNoBodyTemplate} />
                <Column field="created" header="Date Build" style={{ minWidth: '12rem' }} body={dateCreatedTemplate}/>
                <Column field="final_short_url" header="Final Linking URL" sortable style={{ minWidth: '12rem' }} body={shortUrlTemplate}/>
                <Column field="first_source" header="Source" sortable style={{ minWidth: '8rem' }} />
                <Column field="first_medium" header="Medium" sortable style={{ minWidth: '8rem' }} />
                <Column field="first_campaign" header="Campaign" sortable style={{ minWidth: '8rem' }} />
                <Column field="first_content" header="Content" sortable style={{ minWidth: '8rem' }} />
                <Column field="base_url" header="Normal Product Link" sortable style={{ minWidth: '12rem' }} body={baseUrlTemplate}/>
                <Column field="long_url" header="Full UTM URL" sortable style={{ minWidth: '12rem' }} body={longUrlTemplate}/>
                <Column field="first_name" header="Created" sortable style={{ minWidth: '8rem' }} />
                <Column field="times_followed" header="Times Clicked" sortable style={{ minWidth: '3rem' }} />
                <Column header="Click Details" style={{ minWidth: '1rem' }} body={ClickDetailsTemplate}/>
                
            </DataTable>

            <Dialog header="Click Details" visible={Visible} style={{ minWidth: '30vw' }} onHide={handleOnHide}>
                <div>
                    <div className='purchase-form-group'>
                    <div className="card flex justify-content-center">
                    <DataTable value={tracker} loading={trackLoading} showGridlines paginator rows={10} dataKey="id">
                        <Column field="click_count" header="Click Count" sortable style={{ minWidth: '1rem' }}></Column>
                        <Column field="clicked_at" header="Clicked At" style={{ minWidth: '12rem' }} body={clickedAtTemplate}/>
                    </DataTable>
                    </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}